.Home {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	/* margin-right: auto; */
	/* margin-left: auto; */
}
