.Landing {
	text-align: center;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.greeting {
	animation: fadeIn ease 5s;
	-webkit-animation: fadeIn ease 5s;
	-moz-animation: fadeIn ease 5s;
	-o-animation: fadeIn ease 5s;
	-ms-animation: fadeIn ease 5s;
	font-size: calc(10px + 2vmin);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.enter {
	animation: fadeIn ease 10s;
	/* -webkit-animation: fadeIn ease 100s;
  -moz-animation: fadeIn ease 100s;
  -o-animation: fadeIn ease 100s;
  -ms-animation: fadeIn ease 100s; */
	font-size: calc(5px + 1vmin);
	justify-content: center;
	text-align: center;
}